<template>
  <c-box>
    <c-grid
      v-if="!selectedMonth"
      :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
      gap="6"
      :padding="['16px', '0']"
    >
      <c-box
        v-for="(item) in data?.processedResult || []"
        :key="item.id"
        class="card"
        border-radius="8px"
        width="100%"
        box-shadow="0px 4px 8px rgba(0, 0, 0, 0.2)"
        cursor="pointer"
        transition="all 0.2s ease-in-out"
        position="relative"
        :_hover="{
          backgroundColor: '#e8e7e7',
        }"
        :background="item.isUnlock ? 'unset' : 'linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) )'"
        @click="clickDetailContent(item.monthGroup, item.isUnlock)"
      >
        <c-box
          position="relative"
          height="120px"
          border-radius="8px"
          overflow="hidden"
          :background-image="`linear-gradient(180deg, rgba(0, 0, 0, 0.22) 37.06%, rgba(0, 0, 0, 0.40) 70.22%), url('${item.image}')`"
          background-size="cover"
          background-position="center"
        >
          <c-box
            position="absolute"
            bottom="15%"
            left="6%"
          >
            <BaseText
              size-mobile="18px"
              size-desktop="20px"
              color="white"
            >
              Bulan {{ item.monthGroup }}
            </BaseText>
          </c-box>
        </c-box>
        <c-box padding="10px">
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
            color="#008C81"
          >
            {{ item.title }}
          </BaseText>
          <BaseText
            size-mobile="12px"
            size-desktop="14px-2"
            margin-top="12px"
            class="d-clamp"
          >
            {{ item.description }}
          </BaseText>
        </c-box>
      </c-box>
    </c-grid>
    <c-box
      v-else
    >
      <c-flex
        p="16px"
        gap="8px"
        width="100%"
        align-items="center"
        :bg="['#F2F2F2', '#FFF']"
      >
        <c-box width="100%">
          <c-form-control
            pos="relative"
            w="100%"
          >
            <c-image
              :src="require('@/assets/ic-search.svg')"
              alt="icon"
              position="absolute"
              top="50%"
              transform="translateY(-50%)"
              :left="['15px', '25px']"
              :height="['20px', '24px']"
              z-index="2"
            />
            <c-input
              id="search"
              v-model="search"
              type="text"
              w="100%"
              :height="['40px', '62px']"
              :font-size="['14px', '18px']"
              placeholder="Search"
              :border-radius="['30px', '20px']"
              :padding-left="['45px', '60px']"
              position="relative"
              z-index="1"
              @input="handleSearch"
            />
          </c-form-control>
        </c-box>
        <c-menu
          :auto-select="false"
          :close-on-select="true"
          @blur="true"
        >
          <c-menu-button
            :background-color="['white', '#F2F2F2']"
            color="gray.900"
            :p="['0', '8px 20px']"
            :border-radius="['8px', '1rem']"
            d="flex"
            align-items="center"
            gap="12px"
            :h="['40px', '62px']"
            :w="['40px', 'fit-content']"
          >
            <c-text
              :display="['none', 'block']"
              color="#333333"
              font-size="16px"
            >
              Filter
            </c-text>
            <c-box
              :display="['none', 'block']"
              :width="['20px', '30px']"
              :height="['20px', '30px']"
              gap="8px"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-filter.svg')"
                height="100%"
                width="100%"
                fill="#333333"
              />
            </c-box>
            <c-box
              :display="['block', 'none']"
              :width="['20px', '30px']"
              :height="['20px', '30px']"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-filter.svg')"
                height="100%"
                width="100%"
                fill="#008C81"
              />
            </c-box>
            <c-flex
              v-if="subCategoryParse.length > 0"
              pos="absolute"
              top="-2px"
              right="-2px" 
              :width="['8px', '10px']"
              :height="['8px', '10px']"
              align-items="center"
              justify-content="center"
              border-radius="100%"
              font-size="10px"
              background-color="danger.400"
              color="#FFF"
            >
              <!-- {{ subCategoryParse.length }} -->
            </c-flex>
          </c-menu-button>
          <c-menu-list
            z-index="10"
            :min-width="['250px', '350px']"
            :max-width="['300px', '400px']"
            flex-direction="column" 
            py="12px"
            px="8px"
            placement="left-start"
            border-radius="1rem"
          >
            <c-box
              v-for="(category, i) in categories"
              v-show="categories.length > 0"
              :key="category.id"
            >
              <c-menu-item
                v-chakra="{
                  ':hover': {
                    backgroundColor: 'primary.50'
                  }
                }"
                gap="12px"
                :px="['8px', '16px']"
                :py="['4px', '8px']"
                :background-color="onSubcategoryIncludes(category) || categoryParse.includes(category.id) ? 'primary.50' : '#FFF'"
                cursor="pointer"
                @click="onChangeFilter(category.id)"
              >
                <c-image
                  :src="category?.image"
                  width="25px"
                  height="25px"
                />
                <c-text
                  color="primary.400"
                  :font-size="['14px', '18px']"
                  :font-weight="['500']"
                >
                  {{ category?.categoryName }}
                </c-text>
              </c-menu-item>
              <c-menu-group 
                v-for="sub in category?.subCategories"
                :key="sub.id"
                title=""
              >
                <c-menu-item
                  v-chakra="{
                    ':hover': {
                      background: subCategoryParse.includes(sub.id) ? 'primary.50' : '#F2F2F2',
                    }
                  }" 
                  d="flex" 
                  justify-content="space-between"
                  :pl="['48px', '16px']"
                  :pr="['28px', '8px']"
                  :font-size="['12px', '16px']"
                  font-weight="400"
                  color="#000"
                  :min-height="['26px', '32px']"
                  :background="categoryParse.includes(category?.id) || subCategoryParse.includes(sub.id) ? 'primary.50' : '#FFF'"
                  @click="onChangeSubCategory(sub?.id, category?.subCategories)"
                >
                  {{ sub?.subCategoryName }}
                  <inline-svg
                    :src="require('@/assets/icons/icon-chevron-right.svg')"
                    height="16px"
                    width="16px"
                  />
                </c-menu-item>
              </c-menu-group> 
              <c-menu-divider
                v-if="categories.length !== (i+1)"
                border-color="#FFF"
                py="4px"
                m="0"
              />
            </c-box>
            <c-text
              v-show="categories.length === 0"
              px="1rem"
              font-size="16px"
              font-weight="500"
              color="danger.400"
            >
              Belum ada kategori
            </c-text>
          </c-menu-list>
        </c-menu>
      </c-flex>

      <c-box
        pos="relative"
        bg="#FFF"
      >
        <c-flex
          v-if="!isLoadingCategory"
          v-dragscroll
          flex-direction="row"
          align-items="center"
          flex-wrap="no-wrap"
          gap="4px"
          overflow="hidden"
          max-width="890px"
          py="8px"
          mx="10px"
        >
          <c-box
            v-chakra="{
              ':hover': {
                backgroundColor: subCategoryParse.length === 0 ? (categoryParse.length === 0 ? 'primary.50' : '#F2F2F2') : '#F2F2F2',
              },
            }"
            :min-width="['80px', '130px']"
            :max-width="['80px', '150px']"
            w="100%"
            border-radius="8px"
            :background-color="subCategoryParse.length === 0 ? (categoryParse.length === 0 ? 'primary.50' : '#FFF') : '#FFF'"
            text-align="center"
            p="6px"
            cursor="pointer"
            @click="onChangeCategory('all')"
          >
            <c-image
              src="https://ik.imagekit.io/dietela/pwa_webp/content_challenge/select%202.webp?updatedAt=1695810504668"
              :w="['40px', '60px']"
              :h="['40px', '60px']"
              mb="4px"
              mx="auto"
            />
            <c-text
              font-size="12px"
              color="#111"
              font-weight="500"
            >
              Semua
            </c-text>
          </c-box>
          <c-box
            v-for="item in categories"
            v-show="categories.length > 0"
            :key="item.id"
            v-chakra="{
              ':hover': {
                backgroundColor: onSubcategoryIncludes(item) || categoryParse.includes(item.id) ? 'primary.50' : '#F2F2F2',
              },
            }"
            :min-width="['90px', '130px']"
            :max-width="['90px', '150px']"
            w="100%"
            border-radius="8px"
            :background-color="onSubcategoryIncludes(item) || categoryParse.includes(item.id) ? 'primary.50' : '#FFF'"
            text-align="center"
            p="6px"
            cursor="pointer"
            @click="onChangeCategory(item.id)"
          >
            <c-image
              :src="item?.image"
              :w="['40px', '60px']"
              :h="['40px', '60px']"
              mb="4px"
              mx="auto"
            />
            <c-text
              font-size="12px"
              color="#111"
              font-weight="500"
            >
              {{ item?.categoryName }}
            </c-text>
          </c-box>
        </c-flex>
        <c-flex
          v-else
          v-dragscroll
          flex-direction="row"
          align-items="center"
          flex-wrap="no-wrap"
          gap="4px"
          overflow="hidden"
          max-width="890px"
          py="8px"
          mx="10px"
        >
          <SkeletonMenuCategory 
            v-for="i in 7"
            :key="i"
          />
        </c-flex>
      </c-box>

      <c-box>
        <c-tabs
          class="custom-tab"
          is-fitted
          is-manual
          :index="$route.query?.status ? parseInt($route.query?.status) : 0"
          :default-index="$route.query?.status ? parseInt($route.query?.status) : 0"
          @change="onChangeStatus"
        >
          <c-tab-list>
            <c-tab :class="tabStatus?.semua ? 'tab-badge' : ''">
              Semua
            </c-tab>
            <c-tab :class="tabStatus?.belumTerbaca ? 'tab-badge' : ''">
              Belum Terbaca
            </c-tab>
            <c-tab :class="tabStatus?.sudahTerbaca ? 'tab-badge' : ''">
              Sudah Terbaca
            </c-tab>
            <c-tab :class="tabStatus?.dapatDibaca ? 'tab-badge' : ''">
              Dapat Dibaca
            </c-tab>
          </c-tab-list>
        </c-tabs>
      </c-box>

      <c-grid
        v-if="isLoading"
        width="100%"
        :template-columns="['repeat(2, 1fr)', 'repeat(3, 1fr)']"
        :gap="['3', '4']"
        :px="['1rem', '0']"
        py="1rem"
        :background-color="['#F2F2F2', '#FFF']"
      >
        <SkeletonCardContent 
          v-for="i in (['sm', 'md'].includes(currentBreakpoint) ? 4 : 6)"
          :key="i"
        />
      </c-grid>
      <c-box v-else>
        <c-grid
          v-if="items.length > 0"
          width="100%"
          :template-columns="['repeat(2, 1fr)', 'repeat(3, 1fr)']"
          :gap="['3', '4']"
          :px="['1rem', '0']"
          py="1rem"
          :background-color="['#F2F2F2', '#FFF']"
        >
          <CardContent 
            v-for="(item, i) in items"
            :key="i"
            :title="item?.title"
            :type="item?.type ?? 'materi_harian'"
            :thumbnail="item?.coverImage"
            :is-readed="!!item?.isRead"
            :is-locked="!item?.unlockedStatus"
            @click="onClickContent(item?.id, item?.unlockedStatus)"
          />
        </c-grid>

        <c-flex
          v-if="items.length > 0"
          :background-color="['#F2F2F2', '#FFF']"
          :px="['1rem', '0']"
          pt="8px"
          width="100%"
          gap="4"
        >
          <BaseButton
            variant="outlined"
            flex-grow="1"
            :height="['40px', '60px']"
            background-color="#FFF"
            :left-svg-icon="require('@/assets/icons/icon-left.svg')"
            left-svg-icon-color="#008C81"
            border-radius="40px"
            :is-disabled="page == 1"
            @click="onPrevious"
          >
            Sebelumnya
          </BaseButton>
          <BaseButton
            flex-grow="1"
            :height="['40px', '60px']"
            :right-svg-icon="require('@/assets/icons/icon-right.svg')"
            right-svg-icon-color="#FFF"
            border-radius="40px"
            :is-disabled="page == totalPage"
            @click="onNext"
          >
            Selanjutnya
          </BaseButton>
        </c-flex>

        <c-box
          v-if="items.length === 0"
          width="100%"
          p="60px 40px"
          :bg="['#F2F2F2', '#FFF']"
        >
          <c-image
            src="https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720"
            :width="['200px', '300px']"
            mx="auto"
            mb="16px"
          />
          <c-text
            :font-size="['16px', '18px']"
            font-weight="500"
            text-align="center"
          >
            Yah! materi tidak ditemukan
          </c-text>
        </c-box>
      </c-box>
      
      <c-flex
        v-if="data.clientType !== 'intensive'"
        :pos="['fixed', 'absolute']"
        width="100%"
        :bottom="['0', '25px']"
        left="0"
        flex-direction="column"
        justify-content="center"
        align-items="center"
        background="linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0.7) 71.87%)"
        :border-bottom-radius="['0', '12px']"
      >
        <c-box
          :pt="['250px','150px']"
          :pb="['30px','60px']"
          px="1rem"
          text-align="center"
        >
          <c-text
            :font-size="['16px', '20px']"
            :font-weight="[600, 700]"
            color="#FFF"
            margin-bottom="1rem"
          >
            Akses seluruh konten premium<br>
            dengan mengikuti program Dietela
          </c-text>
          <BaseButton
            color="secondary"
            height="50px"
            :min-width="['250px', '400px']"
            padding="10px 50px"
            border-radius="50px"
            @click="$router.push({name: 'client.select-program'})"
          >
            <c-text 
              :font-size="['14px', '16px']"
              color="#BC5204" 
              font-weight="600"
            >
              Pilih Program
            </c-text>
          </BaseButton>
        </c-box>
      </c-flex>
    </c-box>

    <!-- MODAL -->
    <BaseModal
      :is-open="showModalGetProgram"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="160px"
            object-fit="cover"
            :src="require('@/assets/images/ecommerce-campaign.svg')"
            alt="image get program"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
          flex-direction="column"
        >
          <BaseText
            size-mobile="20px"
            size-desktop="28px"
            color="primary.400"
            text-align="center"
          >
            Yah! Materi tidak bisa dibuka
          </BaseText>
          <BaseText
            size-mobile="14px-2"
            size-desktop="16px"
            text-align="center"
            margin-top="16px"
          >
            Yuk, pilih layanan intensive agar bisa membuka konten ini
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            @click="$router.push({name: 'client.select-program'})"
          >
            Pilih Program
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="showModalGetProgramForIntensive"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="160px"
            object-fit="cover"
            :src="require('@/assets/images/ecommerce-campaign.svg')"
            alt="image get program"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
          flex-direction="column"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
            text-align="center"
          >
            Yah, konten belum bisa dibuka <br>
            karena diluar durasi program kamu saat ini
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            @click="$router.push({name: 'client.select-program'})"
          >
            Pilih Program
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="showModalNotSufficientPoint"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="160px"
            object-fit="cover"
            :src="require('@/assets/images/bg-2.jpeg')"
            alt="image get program"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
          flex-direction="column"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
            text-align="center"
          >
            Yah, kamu tidak dapat membuka konten karena poinmu tidak cukup
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            @click="showModalNotSufficientPoint = false"
          >
            Kembali
          </BaseButton>
        </c-box>
      </template>
    </BaseModal>

    <BaseModal
      :is-open="showModalOpenContent"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="160px"
            object-fit="cover"
            :src="require('@/assets/images/bg-1.jpeg')"
            alt="image get program"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
          flex-direction="column"
        >
          <BaseText
            size-mobile="20px"
            size-desktop="28px"
            color="primary.400"
            text-align="center"
          >
            Apa kamu yakin ingin menggunakan {{ $route.query?.type === 'materi_harian' ? '1' : $route.query?.type === 'target_harian' ? '2' : '[?]' }} poin untuk konten ini?
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-flex
          gap="8px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            color="danger"
            rounded="1000px"
            width="100%"
            :right-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            right-svg-icon-color="white"
            :disabled="isLoadingOpenContent"
            @click="showModalOpenContent = false"
          >
            Batal
          </BaseButton>
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            :is-loading="isLoadingOpenContent"
            @click="onUnlockContent"
          >
            Konfirmasi
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import { dragscroll } from 'vue-dragscroll'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { isDataEmpty } from '@/utils/is-data-empty'
import BaseModal from '@/components/elements/base-modal.vue'
import CardContent from '@/views/content-challenge/_widgets/card-content.vue'
import _ from 'lodash'
import SkeletonCardContent from '@/views/content-challenge/_widgets/skeleton-card-content.vue'
import SkeletonMenuCategory from '@/views/content-challenge/_widgets/skeleton-menu-category.vue'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'
import { reqUnlockContent } from '@/requests/dietela-api/client/content-challenge/req-unlock-content'
import { CMenuItem, CText, CBox, CGrid, CFlex, CFormControl, CImage, CInput, CMenu, CMenuButton, CMenuList, CMenuGroup, CMenuDivider, CTab, CTabs, CTabList } from '@chakra-ui/vue'

export default {
  name: 'TargetHarian',
  components: {
    BaseModal,
    BaseText,
    BaseButton,
    CardContent,
    SkeletonCardContent,
    SkeletonMenuCategory,
    CMenuItem,
    CText,
    CBox,
    CGrid,
    CFlex,
    CFormControl,
    CImage,
    CInput,
    CMenu,
    CMenuButton,
    CMenuList,
    CMenuGroup,
    CMenuDivider,
    CTab,
    CTabs,
    CTabList,
  },
  directives: {
    dragscroll,
  },
  mixins: [
    mixinsCheckBreakpoints(),
  ],
  props: {
    type: {
      type: String,
      default: 'target-harian',
    },
    data: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: String,
      default: '10',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoadingCategory: {
      type: Boolean,
      default: false,
    },
    tabStatus: {
      type: Object,
      default: () => ({
        semua: false,
        belumTerbaca: false,
        sudahTerbaca: false,
        dapatDibaca: false,
      }),
    },
    showModalType: {
      type: String,
      default: null,
    },
  },
  emits: ['on-click'],
  data() {
    return {
      showModalGetProgram: false,
      showModalGetProgramForIntensive: false,
      showModalNotSufficientPoint: false,
      showModalOpenContent: false,
      titleModalGetProgram: '',
      search: this.$route.query.q ?? '',
      debounceSearch: _.debounce(this.onSearch, 1000),
      selectedContentId: null,
      isLoadingOpenContent: false,
    }
  },
  computed: {
    selectedMonth() {
      return this.$route.query.month ? this.$route.query.month : null
    },
    categoryParse() {
      return this.$route.query?.category ? JSON.parse(this.$route.query?.category) : []
    },
    subCategoryParse() {
      return this.$route.query?.subCategories ? JSON.parse(this.$route.query?.subCategories) : []
    },
    totalPage() {
      return Math.ceil(this.total / Number(this.perPage))
    },
  },
  watch: {
    showModalType: {
      immediate: true,
      async handler(val) {
        if (val === 'UNLOCK_CONTENT') {
          this.showModalOpenContent = true
        }
        if (val === 'ERROR_GET_INTENSIVE_PROGRAM') {
          this.showModalGetProgram = true
        }
        if (val === 'ERROR_GET_INTENSIVE_LONGER_DURATION') {
          this.showModalGetProgramForIntensive = true
        }
      },
    },
  },
  methods: {
    isDataEmpty,
    clickDetailContent(selectedMonth, isUnlock) {
      if (isUnlock === 1) {
        this.$emit('on-click', selectedMonth)
        return
      }
      if (this.data.clientType === 'non-intensive' && selectedMonth === 1) {
        this.$emit('on-click', selectedMonth)
        return
      }
      if (this.data.clientType === 'non-intensive') {
        this.showModalGetProgram = true
        return
      } else if (this.data.clientType === 'intensive') {
        this.showModalGetProgramForIntensive = true
        return
      }
      this.$emit('on-click', selectedMonth)
    },
    onClickContent(id, unlockedStatus) {
      if (unlockedStatus === 1) {
        this.$router.push({
          name: 'content-challenge.detail',
          params: {
            id: id,
          },
        })
        return
      }

      if (this.data.clientType === 'non-intensive') {
        this.showModalGetProgram = true
        return
      }

      if (this.data.clientType === 'intensive') {
        this.showModalOpenContent = true
        this.selectedContentId = id
        return
      }
    },
    async onUnlockContent() {
      try {
        this.isLoadingOpenContent = true
        await reqUnlockContent(
          this.$store.getters.axios,
          this.$route.query?.contentId || this.selectedContentId,
        )
        this.showModalOpenContent = false
        this.$toast({
          title: 'Berhasil',
          description: 'Konten berhasil dibuka',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        this.$router.replace({
          name: this.$route.name,
          query: {
            type: this.$route.query?.type,
            month: this.$route.query?.month,
            _refresh: Date.now(),
          },
        })

        if (this.$route.query?.contentId) {
          this.$router.push({
            name: 'content-challenge.detail',
            params: {
              id: this.$route.query?.contentId,
            },
          })
        }
      } catch (e) {
        this.showModalOpenContent = false
        this.showModalNotSufficientPoint = true
      } finally {
        this.isLoadingOpenContent = false
      }
    },
    handleSearch() {
      this.debounceSearch()
    },
    onSearch() {
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          q: this.search,
          page: 1,
        },
        params: { savePosition: true },
      })
    },
    onPrevious() {
      if (this.page > 1) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page: (this.page - 1) },
        })
      }
    },
    onNext() {
      if (this.page < this.totalPage) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page: (this.page + 1) },
        })
      }
    },
    onChangeCategory(value) {
      if (value === 'all') {
        this.$router.push({
          name: this.$route.name,
          query: { 
            ...this.$route.query,
            page: 1,
            category: '',
            subCategories: '',
          },
          params: { savePosition: true },
        }).catch(() => {})
      } else {
        let category = [value]
        category = JSON.stringify(category)
        this.$router.push({
          name: this.$route.name,
          query: { 
            ...this.$route.query,
            page: 1,
            category: category.length === 0 ? '' : category,
            subCategories: '',
          },
          params: { savePosition: true },
        }).catch(() => {})
      }
    },
    onChangeFilter(value) {
      let category = this.categoryParse
      if (category && category?.some((it) => it === value)) {
        this.onChangeCategory('all')
      } else {
        category = JSON.stringify([value])
        this.$router.push({
          name: this.$route.name,
          query: { 
            ...this.$route.query,
            page: 1,
            category: category.length === 0 ? '' : category,
            subCategories: '',
          },
          params: { savePosition: true },
        }).catch(() => {})
      }
    },
    onChangeSubCategory(value, list) {
      let subcategory = this.subCategoryParse
      if (list.some((it) => it.id === value)) {
        if (subcategory && subcategory?.some((it) => it === value)) {
          subcategory = subcategory?.filter((it) => it !== value)
        } else {
          subcategory.push(value)
        }
      } else {
        subcategory = [value]
      }
      subcategory = JSON.stringify(subcategory)
      this.$router.push({
        name: this.$route.name,
        query: { 
          ...this.$route.query,
          page: 1,
          category: '',
          subCategories: subcategory.length === 0 ? '' : subcategory,
        },
        params: { savePosition: true },
      }).catch(() => {})
    },
    onSubcategoryIncludes(category) {
      if (this.subCategoryParse.length > 0 && category?.subCategories?.length > 0) {
        return category?.subCategories.some((it) => it.id === this.subCategoryParse[0])
      }
      return false
    },
    onChangeStatus(value) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: 1,
          status: value,
        },
        params: { savePosition: true },
      }).catch(() => {})
    },
  },
}
</script>

<style scoped>
::v-deep .d-clamp > span {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

::v-deep .card:hover .card-options {
  opacity: 1;
}

::v-deep .custom-tab {
  overflow: auto;
  overflow-y: hidden;
  padding: 5px;
  border-top: 6px solid #F2F2F2;
  border-bottom: 2px solid white;
}
::v-deep .custom-tab > div > button {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #888888;
  white-space: nowrap;
  border-bottom: 2px solid white;
}
::v-deep .custom-tab > div > button[aria-selected="true"] {
  color: #008C81;
  border-bottom: 2px solid #008C81;
}
::v-deep .custom-tab > div > button.tab-badge {
  position: relative;
}
::v-deep .custom-tab > div > button.tab-badge::after {
  content: "";
  width: 7px;
  height: 7px;
  background: red;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
}
@media (min-width: 768px) {
  ::v-deep .custom-tab {
    border-top: none;
    margin-top: 32px;
  }
  ::v-deep .custom-tab > div > button {
    font-size: 18px;
  }
  ::v-deep .custom-tab > div > button.tab-badge::after {
    content: "";
    width: 10px;
    height: 10px;
  }
}
</style>
