var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [!_vm.isEmpty ? _c('c-flex', {
    attrs: {
      "p": "16px",
      "gap": "8px",
      "width": "100%",
      "align-items": "center",
      "bg": ['#F2F2F2', '#FFF']
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "w": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "position": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "left": ['15px', '25px'],
      "height": ['20px', '24px'],
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "height": ['40px', '62px'],
      "font-size": ['14px', '18px'],
      "placeholder": "Search",
      "border-radius": ['30px', '20px'],
      "padding-left": ['45px', '60px'],
      "position": "relative",
      "z-index": "1"
    },
    on: {
      "input": _vm.handleSearch
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('c-box', [_c('c-tabs', {
    staticClass: "custom-tab",
    attrs: {
      "is-fitted": "",
      "is-manual": "",
      "index": _vm.activeTabIndex,
      "default-index": _vm.activeTabIndex
    },
    on: {
      "change": _vm.onChangeTab
    }
  }, [_c('c-tab-list', _vm._l(['Semua', 'Tips Gizi', 'Materi Harian', 'Target Harian'], function (label, index) {
    return _c('c-tab', {
      key: index
    }, [_vm._v(" " + _vm._s(label) + " ")]);
  }), 1)], 1)], 1), _vm.isLoading ? _c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": ['repeat(2, 1fr)', 'repeat(3, 1fr)'],
      "gap": ['3', '4'],
      "px": ['1rem', '0'],
      "py": "1rem",
      "background-color": ['#F2F2F2', '#FFF']
    }
  }, _vm._l(['sm', 'md'].includes(_vm.currentBreakpoint) ? 4 : 6, function (i) {
    return _c('SkeletonCardContent', {
      key: i
    });
  }), 1) : _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_vm.items.length > 0 ? _c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": ['repeat(2, 1fr)', 'repeat(3, 1fr)'],
      "gap": ['3', '4'],
      "px": ['1rem', '0'],
      "py": "1rem",
      "background-color": ['#F2F2F2', '#FFF']
    }
  }, _vm._l(_vm.items, function (item, i) {
    var _item$type;

    return _c('CardContent', {
      key: i,
      attrs: {
        "title": item === null || item === void 0 ? void 0 : item.title,
        "type": (_item$type = item === null || item === void 0 ? void 0 : item.type) !== null && _item$type !== void 0 ? _item$type : 'tips_gizi',
        "thumbnail": item === null || item === void 0 ? void 0 : item.coverImage,
        "is-locked": false,
        "is-readed": item !== null && item !== void 0 && item.isRead ? true : false
      },
      on: {
        "click": function click($event) {
          return _vm.onClickContent(item === null || item === void 0 ? void 0 : item.id);
        }
      }
    });
  }), 1) : _vm._e(), _vm.items.length > 0 ? _c('c-flex', {
    attrs: {
      "background-color": ['#F2F2F2', '#FFF'],
      "px": ['1rem', '0'],
      "pt": "8px",
      "width": "100%",
      "gap": "4"
    }
  }, [_c('BaseButton', {
    attrs: {
      "variant": "outlined",
      "flex-grow": "1",
      "height": ['40px', '60px'],
      "background-color": "#FFF",
      "left-svg-icon": require('@/assets/icons/icon-left.svg'),
      "left-svg-icon-color": "#008C81",
      "border-radius": "40px",
      "is-disabled": _vm.page == 1
    },
    on: {
      "click": _vm.onPrevious
    }
  }, [_vm._v(" Sebelumnya ")]), _c('BaseButton', {
    attrs: {
      "flex-grow": "1",
      "height": ['40px', '60px'],
      "right-svg-icon": require('@/assets/icons/icon-right.svg'),
      "right-svg-icon-color": "#FFF",
      "border-radius": "40px",
      "is-disabled": _vm.page == _vm.totalPage
    },
    on: {
      "click": _vm.onNext
    }
  }, [_vm._v(" Selanjutnya ")])], 1) : _vm._e(), _vm.items.length == 0 ? _c('c-box', {
    attrs: {
      "width": "100%",
      "p": "60px 40px",
      "bg": ['#F2F2F2', '#FFF']
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.search ? 'https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720' : require('@/assets/icons/image-empty-cart.svg'),
      "width": ['200px', '300px'],
      "mx": "auto",
      "mb": "16px"
    }
  }), _vm.search ? _c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "text-align": "center"
    }
  }, [_vm._v(" Yah! materi tidak ditemukan ")]) : _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "text-align": "center"
    }
  }, [_vm._v(" Anda tidak memiliki konten favorit" + _vm._s(_vm.tab ? " ".concat(_vm.capitalizeWords(_vm.tab)) : '') + ", ")]), _c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "text-align": "center"
    }
  }, [_vm._v(" silahkan tandai konten favorit anda ")])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }