<template>
  <c-box
    width="100%"
  >
    <c-flex
      v-if="!isEmpty"
      p="16px"
      gap="8px"
      width="100%"
      align-items="center"
      :bg="['#F2F2F2', '#FFF']"
    >
      <c-box width="100%">
        <c-form-control
          pos="relative"
          w="100%"
        >
          <c-image
            :src="require('@/assets/ic-search.svg')"
            alt="icon"
            position="absolute"
            top="50%"
            transform="translateY(-50%)"
            :left="['15px', '25px']"
            :height="['20px', '24px']"
            z-index="2"
          />
          <c-input
            id="search"
            v-model="search"
            type="text"
            w="100%"
            :height="['40px', '62px']"
            :font-size="['14px', '18px']"
            placeholder="Search"
            :border-radius="['30px', '20px']"
            :padding-left="['45px', '60px']"
            position="relative"
            z-index="1"
            @input="handleSearch"
          />
        </c-form-control>
      </c-box>
    </c-flex>

    <c-box>
      <c-tabs
        is-fitted
        is-manual
        :index="activeTabIndex"
        :default-index="activeTabIndex"
        class="custom-tab"
        @change="onChangeTab"
      >
        <c-tab-list>
          <c-tab
            v-for="(label, index) in [
              'Semua',
              'Tips Gizi',
              'Materi Harian',
              'Target Harian'
            ]"
            :key="index"
          >
            {{ label }}
          </c-tab>
        </c-tab-list>
      </c-tabs>
    </c-box>

    <c-grid
      v-if="isLoading"
      width="100%"
      :template-columns="['repeat(2, 1fr)', 'repeat(3, 1fr)']"
      :gap="['3', '4']"
      :px="['1rem', '0']"
      py="1rem"
      :background-color="['#F2F2F2', '#FFF']"
    >
      <SkeletonCardContent 
        v-for="i in (['sm', 'md'].includes(currentBreakpoint) ? 4 : 6)"
        :key="i"
      />
    </c-grid>
    <c-box
      v-else
      width="100%"
    >
      <c-grid
        v-if="items.length > 0"
        width="100%"
        :template-columns="['repeat(2, 1fr)', 'repeat(3, 1fr)']"
        :gap="['3', '4']"
        :px="['1rem', '0']"
        py="1rem"
        :background-color="['#F2F2F2', '#FFF']"
      >
        <CardContent 
          v-for="(item, i) in items"
          :key="i"
          :title="item?.title"
          :type="item?.type ?? 'tips_gizi'"
          :thumbnail="item?.coverImage"
          :is-locked="false"
          :is-readed="item?.isRead ? true : false"
          @click="onClickContent(item?.id)"
        />
      </c-grid>

      <c-flex
        v-if="items.length > 0"
        :background-color="['#F2F2F2', '#FFF']"
        :px="['1rem', '0']"
        pt="8px"
        width="100%"
        gap="4"
      >
        <BaseButton
          variant="outlined"
          flex-grow="1"
          :height="['40px', '60px']"
          background-color="#FFF"
          :left-svg-icon="require('@/assets/icons/icon-left.svg')"
          left-svg-icon-color="#008C81"
          border-radius="40px"
          :is-disabled="page == 1"
          @click="onPrevious"
        >
          Sebelumnya
        </BaseButton>
        <BaseButton
          flex-grow="1"
          :height="['40px', '60px']"
          :right-svg-icon="require('@/assets/icons/icon-right.svg')"
          right-svg-icon-color="#FFF"
          border-radius="40px"
          :is-disabled="page == totalPage"
          @click="onNext"
        >
          Selanjutnya
        </BaseButton>
      </c-flex>

      <c-box
        v-if="items.length == 0"
        width="100%"
        p="60px 40px"
        :bg="['#F2F2F2', '#FFF']"
      >
        <c-image
          :src="search ? 'https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720' : require('@/assets/icons/image-empty-cart.svg')"
          :width="['200px', '300px']"
          mx="auto"
          mb="16px"
        />
        <c-text
          v-if="search"
          :font-size="['16px', '18px']"
          font-weight="500"
          text-align="center"
        >
          Yah! materi tidak ditemukan
        </c-text>
        <c-box
          v-else
        >
          <c-text
            :font-size="['16px', '18px']"
            font-weight="500"
            text-align="center"
          >
            Anda tidak memiliki konten favorit{{ tab ? ` ${capitalizeWords(tab)}` : '' }},
          </c-text>
          
          <c-text
            :font-size="['16px', '18px']"
            font-weight="500"
            text-align="center"
          >
            silahkan tandai konten favorit anda
          </c-text>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import BaseButton from '@/components/elements/base-button.vue'
import CardContent from '@/views/content-challenge/_widgets/card-content.vue'
import _ from 'lodash'
import SkeletonCardContent from '@/views/content-challenge/_widgets/skeleton-card-content.vue'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'
import { mapGetters } from 'vuex'
import generalMixin from '@/utils/general-mixins'

export default {
  name: 'Favorite',
  components: {
    BaseButton,
    CardContent,
    SkeletonCardContent,
  },
  mixins: [
    mixinsCheckBreakpoints(),
    generalMixin,
  ],
  props: {
    type: {
      type: String,
      default: 'favorite',
    },
    items: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: String,
      default: '10',
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: [],
  data() {
    return {
      search: this.$route.query.q ?? '',
      debounceSearch: _.debounce(this.onSearch, 1000),
    }
  },
  computed: {
    ...mapGetters({
      types: 'contentChallenge/types',
    }),
    totalPage() {
      return Math.ceil(this.total / Number(this.perPage))
    },
    tab() {
      return this.$route.query.tab
    },
    activeTabMap() {
      return {
        all: 0,
        tips_gizi: 1,
        materi_harian: 2,
        target_harian: 3,
      }
    },
    activeTabIndex() {
      return this.activeTabMap[this.tab] ?? 0
    },
  },
  methods: {
    onClickContent(id) {
      this.$router.push({
        name: 'content-challenge.detail',
        params: {
          id: id,
        },
        query: {
          favorite: true,
        },
      })
    },
    handleSearch() {
      this.debounceSearch()
    },
    onSearch() {
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          q: this.search,
          page: 1,
        },
        params: { savePosition: true },
      })
    },
    onPrevious() {
      if (this.page > 1) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page: (this.page - 1) },
        })
      }
    },
    onNext() {
      if (this.page < this.totalPage) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page: (this.page + 1) },
        })
      }
    },
    onChangeTab(index) {
      let key = Object.entries(this.activeTabMap).find(
        ([, value]) => value === index,
      )?.[0]
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          page: 1,
          tab: key,
        },
        params: { savePosition: true },
      }).catch(() => {})
    },
  },
}
</script>

<style scoped>
::v-deep .custom-tab {
  overflow: auto;
  overflow-y: hidden;
  padding: 5px;
  border-top: 6px solid #F2F2F2;
  border-bottom: 2px solid white;
}
::v-deep .custom-tab > div > button {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #888888;
  white-space: nowrap;
  border-bottom: 2px solid white;
}
::v-deep .custom-tab > div > button[aria-selected="true"] {
  color: #008C81;
  border-bottom: 2px solid #008C81;
}
::v-deep .custom-tab > div > button.tab-badge {
  position: relative;
}
::v-deep .custom-tab > div > button.tab-badge::after {
  content: "";
  width: 7px;
  height: 7px;
  background: red;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
}
@media (min-width: 768px) {
  ::v-deep .custom-tab {
    border-top: none;
    margin-top: 32px;
  }
  ::v-deep .custom-tab > div > button {
    font-size: 18px;
  }
  ::v-deep .custom-tab > div > button.tab-badge::after {
    content: "";
    width: 10px;
    height: 10px;
  }
}
</style>