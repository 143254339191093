<template>
  <c-flex
    width="100%"
    flex-direction="column"
    gap="6px"
    border-radius="8px"
    box-shadow="2px 2px 10px 0px #00000026"
    background-color="#FFF"
    transition="all 0.2s ease-in-out"
    position="relative"
  > 
    <c-box
      border-top-radius="8px"
      height="100px"
      width="100%"
      flex-shrink="0"
      overflow="hidden"
    >
      <vue-skeleton-loader
        type="react"
        width="100%"
        height="100%"
        animation="fade"
      />
    </c-box>
    <c-flex
      height="100%"
      flex-direction="column"
      justify-content="space-between"
      p="5px 10px"
      border-bottom-radius="8px"
    >
      <c-box
        mb="12px"
      >
        <c-box
          height="20px"
          width="100%"
          mb="6px"
        >
          <vue-skeleton-loader
            type="react"
            width="100%"
            height="100%"
            animation="fade"
            rounded
          />
        </c-box>
        <c-box
          height="20px"
          width="100%"
        >
          <vue-skeleton-loader
            type="react"
            width="100%"
            height="100%"
            animation="fade"
            rounded
          />
        </c-box>
      </c-box>

      <c-box
        height="16px"
        width="80px"
      >
        <vue-skeleton-loader
          type="react"
          width="100%"
          height="100%"
          animation="fade"
          rounded
        />
      </c-box>
    </c-flex>
  </c-flex>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'  

export default {
  name: 'SkeletonCardContent',
  components: {
    VueSkeletonLoader,
  },
}
</script>

<style>

</style>