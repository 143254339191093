var render = function () {
  var _vm$$route$query, _vm$$route$query2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "bg": ['#F2F2F2', 'white'],
      "h": "100%",
      "min-h": [_vm.$route.query.month ? 'calc(100vh - 64px)' : 'calc(100vh - 128px)', '100%'],
      "pb": "25px",
      "height": "100%"
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": _vm.breadcrumbPath
    }
  })], 1), _c('c-box', {
    attrs: {
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['none', '12px'],
      "w": "100%",
      "px": ['0', '85px'],
      "pb": ['0', '30px']
    }
  }, [_c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": ['inherit', '1200px'],
      "mx": "auto",
      "px": ['0', '20px'],
      "bg": "#FFF"
    }
  }, [['target_harian', 'materi_harian'].includes((_vm$$route$query = _vm.$route.query) === null || _vm$$route$query === void 0 ? void 0 : _vm$$route$query.type) && !!((_vm$$route$query2 = _vm.$route.query) !== null && _vm$$route$query2 !== void 0 && _vm$$route$query2.month) && _vm.isClient ? _c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "padding-top": "32px",
      "display": ['none', 'flex'],
      "margin-bottom": "8px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "p": "0"
    },
    on: {
      "click": _vm.onBackMonth
    }
  }, [_c('c-image', {
    attrs: {
      "w": "24px",
      "h": "24px",
      "object-fit": "cover",
      "src": require('@/assets/icon-chevron-left.svg')
    }
  })], 1), _c('c-text', {
    attrs: {
      "font-size": "20px",
      "color": "#333",
      "font-weight": "700"
    }
  }, [_vm._v(" " + _vm._s(_vm.capitalizeWords(_vm.$route.query.type)) + " ")])], 1), _c('c-box', [_c('UserPoin', {
    attrs: {
      "value": _vm.totalPoinUser
    }
  })], 1)], 1) : _vm._e(), !_vm.$route.query.month || _vm.isRoleNutritionist ? _c('c-box', {
    attrs: {
      "pt": ['20px', '0']
    }
  }, [_c('c-box', {
    attrs: {
      "justify-content": "center",
      "display": ['none', 'flex']
    }
  }, [_c('c-heading', {
    attrs: {
      "display": _vm.isRoleNutritionist ? 'block' : ['none', 'block'],
      "font-size": _vm.isRoleNutritionist ? ['20px', '28px'] : '20px',
      "font-weight": "700",
      "color": "black.900",
      "my": "30px"
    }
  }, [_vm._v(" Panduan Diet ")])], 1), _c('c-box', {
    attrs: {
      "w": "100%",
      "px": "1rem"
    }
  }, [_vm.types.length > 0 ? _c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": ['repeat(4, 1fr)', 'repeat(4, 1fr)'],
      "gap": ['2', '4'],
      "mb": "1rem"
    }
  }, [_vm._l(_vm.types, function (item) {
    return _c('c-flex', {
      key: item.type,
      attrs: {
        "w": "100%",
        "p": ['8px 6px', '1rem'],
        "flex-direction": "column",
        "align-items": "center",
        "justify-content": "center",
        "bg": item.type === _vm.type ? 'primary.400' : '#F2F2F2',
        "color": item.type === _vm.type ? '#FFF' : '#111',
        "border-radius": "1rem",
        "cursor": "pointer",
        "box-shadow": "0px 2px 2px 0px #008C8126",
        "_hover": {
          backgroundColor: '#008C81',
          color: '#FFFFFF'
        }
      },
      on: {
        "click": function click($event) {
          return _vm.onChangeType(item.type);
        }
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['10px', '18px'],
        "font-weight": "600",
        "mb": "8px",
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('c-image', {
      attrs: {
        "src": item.image,
        "alt": "",
        "height": ['40px', '60px']
      }
    })], 1);
  }), _c('c-flex', {
    attrs: {
      "w": "100%",
      "p": ['8px 6px', '1rem'],
      "flex-direction": "column",
      "align-items": "center",
      "justify-content": "center",
      "bg": _vm.type === 'favorite' ? 'primary.400' : '#F2F2F2',
      "color": _vm.type === 'favorite' ? '#FFF' : '#111',
      "border-radius": "1rem",
      "cursor": "pointer",
      "box-shadow": "0px 2px 2px 0px #008C8126",
      "_hover": {
        backgroundColor: '#008C81',
        color: '#FFFFFF'
      }
    },
    on: {
      "click": function click($event) {
        return _vm.onChangeType('favorite');
      }
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['10px', '18px'],
      "font-weight": "600",
      "mb": "8px",
      "text-align": "center"
    }
  }, [_vm._v(" Favorit ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/icon-love.svg'),
      "alt": "",
      "height": ['38px', '60px']
    }
  })], 1)], 2) : _c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": ['repeat(4, 1fr)', 'repeat(4, 1fr)'],
      "gap": ['2', '4'],
      "mb": "1rem"
    }
  }, _vm._l(4, function (item) {
    return _c('c-flex', {
      key: item,
      attrs: {
        "w": "100%",
        "p": ['8px 6px', '1rem'],
        "flex-direction": "column",
        "align-items": "center",
        "justify-content": "center",
        "bg": "#F2F2F2",
        "color": "#111",
        "border-radius": "1rem",
        "cursor": "pointer",
        "box-shadow": "0px 2px 2px 0px #008C8126"
      }
    }, [_c('c-box', {
      attrs: {
        "w": ['45px', '100px'],
        "h": ['12px', '24px'],
        "mb": "8px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade",
        "rounded": ""
      }
    })], 1), _c('c-box', {
      attrs: {
        "w": ['40px', '60px'],
        "h": ['40px', '60px']
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "circle",
        "width": "100%",
        "height": "100%",
        "animation": "fade",
        "rounded": ""
      }
    })], 1)], 1);
  }), 1)], 1)], 1) : _vm._e(), _vm.type === 'materi_harian' && !_vm.isLoadingListMonthMateriHarian ? _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_vm.isClient ? _c('MateriHarian', {
    attrs: {
      "type": _vm.type,
      "data": _vm.listMonthMateriHarian,
      "items": _vm.items,
      "categories": _vm.category,
      "page": _vm.page,
      "per-page": _vm.perPage,
      "total": _vm.total,
      "is-loading": _vm.isLoadingContent,
      "tab-status": _vm.tabStatus,
      "show-modal-type": _vm.showModalType
    },
    on: {
      "on-click": _vm.onChangeMonth
    }
  }) : _vm._e(), _vm.isRoleNutritionist ? _c('MateriHarianNutri', {
    attrs: {
      "type": _vm.type,
      "months": _vm.listMonthMateriHarian,
      "items": _vm.items,
      "categories": _vm.category,
      "page": _vm.page,
      "per-page": _vm.perPage,
      "total": _vm.total,
      "is-loading": _vm.isLoadingContent
    },
    on: {
      "on-click": _vm.onChangeMonth
    }
  }) : _vm._e()], 1) : _vm.type === 'target_harian' && !_vm.isLoadingListMonthTargetHarian ? _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_vm.isClient ? _c('TargetHarian', {
    attrs: {
      "type": _vm.type,
      "data": _vm.listMonthTargetHarian,
      "items": _vm.items,
      "categories": _vm.category,
      "page": _vm.page,
      "per-page": _vm.perPage,
      "total": _vm.total,
      "is-loading": _vm.isLoadingContent,
      "tab-status": _vm.tabStatus,
      "show-modal-type": _vm.showModalType
    },
    on: {
      "on-click": _vm.onChangeMonth
    }
  }) : _vm._e(), _vm.isRoleNutritionist ? _c('TargetHarianNutri', {
    attrs: {
      "type": _vm.type,
      "months": _vm.listMonthTargetHarian,
      "items": _vm.items,
      "categories": _vm.category,
      "page": _vm.page,
      "per-page": _vm.perPage,
      "total": _vm.total,
      "is-loading": _vm.isLoadingContent
    },
    on: {
      "on-click": _vm.onChangeMonth
    }
  }) : _vm._e()], 1) : _vm.type === 'favorite' ? _c('c-box', [_c('Favorite', {
    attrs: {
      "type": _vm.type,
      "items": _vm.items,
      "page": _vm.page,
      "per-page": _vm.perPage,
      "total": _vm.total,
      "is-empty": _vm.isContentEmpty,
      "is-loading": _vm.isLoadingContent
    }
  })], 1) : _vm.type === 'tips_gizi' ? _c('c-box', [_vm.isClient ? _c('TipsGizi', {
    attrs: {
      "type": _vm.type,
      "items": _vm.items,
      "categories": _vm.category,
      "page": _vm.page,
      "per-page": _vm.perPage,
      "total": _vm.total,
      "is-loading": _vm.isLoadingContent,
      "is-loading-category": _vm.isLoadingCategory
    }
  }) : _vm._e(), _vm.isRoleNutritionist ? _c('TipsGiziNutri', {
    attrs: {
      "type": _vm.type,
      "items": _vm.items,
      "categories": _vm.category,
      "page": _vm.page,
      "per-page": _vm.perPage,
      "total": _vm.total
    }
  }) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }