var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "gap": "6px",
      "border-radius": "8px",
      "box-shadow": "2px 2px 10px 0px #00000026",
      "background-color": "#FFF",
      "transition": "all 0.2s ease-in-out",
      "position": "relative"
    }
  }, [_c('c-box', {
    attrs: {
      "border-top-radius": "8px",
      "height": "100px",
      "width": "100%",
      "flex-shrink": "0",
      "overflow": "hidden"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "react",
      "width": "100%",
      "height": "100%",
      "animation": "fade"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "height": "100%",
      "flex-direction": "column",
      "justify-content": "space-between",
      "p": "5px 10px",
      "border-bottom-radius": "8px"
    }
  }, [_c('c-box', {
    attrs: {
      "mb": "12px"
    }
  }, [_c('c-box', {
    attrs: {
      "height": "20px",
      "width": "100%",
      "mb": "6px"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "react",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1), _c('c-box', {
    attrs: {
      "height": "20px",
      "width": "100%"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "react",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "height": "16px",
      "width": "80px"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "react",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }