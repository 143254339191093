var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "p": "16px",
      "gap": "8px",
      "width": "100%",
      "align-items": "center",
      "bg": ['#F2F2F2', '#FFF']
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative",
      "w": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "alt": "icon",
      "position": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "left": ['15px', '25px'],
      "height": ['20px', '24px'],
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "id": "search",
      "type": "text",
      "w": "100%",
      "height": ['40px', '62px'],
      "font-size": ['14px', '18px'],
      "placeholder": "Search",
      "border-radius": ['30px', '20px'],
      "padding-left": ['45px', '60px'],
      "position": "relative",
      "z-index": "1"
    },
    on: {
      "input": _vm.handleSearch
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('c-menu', {
    attrs: {
      "auto-select": false,
      "close-on-select": true
    },
    on: {
      "blur": true
    }
  }, [_c('c-menu-button', {
    attrs: {
      "background-color": ['white', '#F2F2F2'],
      "color": "gray.900",
      "p": ['0', '8px 20px'],
      "border-radius": ['8px', '1rem'],
      "d": "flex",
      "align-items": "center",
      "gap": "12px",
      "h": ['40px', '62px'],
      "w": ['40px', 'fit-content']
    }
  }, [_c('c-text', {
    attrs: {
      "display": ['none', 'block'],
      "color": "#333333",
      "font-size": "16px"
    }
  }, [_vm._v(" Filter ")]), _c('c-box', {
    attrs: {
      "display": ['none', 'block'],
      "width": ['20px', '30px'],
      "height": ['20px', '30px'],
      "gap": "8px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-filter.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#333333"
    }
  })], 1), _c('c-box', {
    attrs: {
      "display": ['block', 'none'],
      "width": ['20px', '30px'],
      "height": ['20px', '30px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-filter.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1), _vm.subCategoryParse.length > 0 ? _c('c-flex', {
    attrs: {
      "pos": "absolute",
      "top": "-2px",
      "right": "-2px",
      "width": ['8px', '10px'],
      "height": ['8px', '10px'],
      "align-items": "center",
      "justify-content": "center",
      "border-radius": "100%",
      "font-size": "10px",
      "background-color": "danger.400",
      "color": "#FFF"
    }
  }) : _vm._e()], 1), _c('c-menu-list', {
    attrs: {
      "z-index": "10",
      "min-width": ['250px', '350px'],
      "max-width": ['300px', '400px'],
      "flex-direction": "column",
      "py": "12px",
      "px": "8px",
      "placement": "left-start",
      "border-radius": "1rem"
    }
  }, [_vm._l(_vm.categories, function (category, i) {
    return _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.categories.length > 0,
        expression: "categories.length > 0"
      }],
      key: category.id
    }, [_c('c-menu-item', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            backgroundColor: 'primary.50'
          }
        },
        expression: "{\n              ':hover': {\n                backgroundColor: 'primary.50'\n              }\n            }"
      }],
      attrs: {
        "gap": "12px",
        "px": ['8px', '16px'],
        "py": ['4px', '8px'],
        "background-color": _vm.onSubcategoryIncludes(category) || _vm.categoryParse.includes(category.id) ? 'primary.50' : '#FFF',
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onChangeFilter(category.id);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": category === null || category === void 0 ? void 0 : category.image,
        "width": "25px",
        "height": "25px"
      }
    }), _c('c-text', {
      attrs: {
        "color": "primary.400",
        "font-size": ['14px', '18px'],
        "font-weight": ['500']
      }
    }, [_vm._v(" " + _vm._s(category === null || category === void 0 ? void 0 : category.categoryName) + " ")])], 1), _vm._l(category === null || category === void 0 ? void 0 : category.subCategories, function (sub) {
      return _c('c-menu-group', {
        key: sub.id,
        attrs: {
          "title": ""
        }
      }, [_c('c-menu-item', {
        directives: [{
          name: "chakra",
          rawName: "v-chakra",
          value: {
            ':hover': {
              background: _vm.subCategoryParse.includes(sub.id) ? 'primary.50' : '#F2F2F2'
            }
          },
          expression: "{\n                ':hover': {\n                  background: subCategoryParse.includes(sub.id) ? 'primary.50' : '#F2F2F2',\n                }\n              }"
        }],
        attrs: {
          "d": "flex",
          "justify-content": "space-between",
          "pl": ['48px', '16px'],
          "pr": ['28px', '8px'],
          "font-size": ['12px', '16px'],
          "font-weight": "400",
          "color": "#000",
          "min-height": ['26px', '32px'],
          "background": _vm.categoryParse.includes(category === null || category === void 0 ? void 0 : category.id) || _vm.subCategoryParse.includes(sub.id) ? 'primary.50' : '#FFF'
        },
        on: {
          "click": function click($event) {
            return _vm.onChangeSubCategory(sub === null || sub === void 0 ? void 0 : sub.id, category === null || category === void 0 ? void 0 : category.subCategories);
          }
        }
      }, [_vm._v(" " + _vm._s(sub === null || sub === void 0 ? void 0 : sub.subCategoryName) + " "), _c('inline-svg', {
        attrs: {
          "src": require('@/assets/icons/icon-chevron-right.svg'),
          "height": "16px",
          "width": "16px"
        }
      })], 1)], 1);
    }), _vm.categories.length !== i + 1 ? _c('c-menu-divider', {
      attrs: {
        "border-color": "#FFF",
        "py": "4px",
        "m": "0"
      }
    }) : _vm._e()], 2);
  }), _c('c-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.categories.length == 0,
      expression: "categories.length == 0"
    }],
    attrs: {
      "px": "1rem",
      "font-size": "16px",
      "font-weight": "500",
      "color": "danger.400"
    }
  }, [_vm._v(" Belum ada kategori ")])], 2)], 1)], 1), _c('c-box', {
    attrs: {
      "pos": "relative",
      "bg": "#FFF"
    }
  }, [!_vm.isLoadingCategory ? _c('c-flex', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll"
    }],
    attrs: {
      "flex-direction": "row",
      "align-items": "center",
      "flex-wrap": "no-wrap",
      "gap": "4px",
      "overflow": "hidden",
      "max-width": "890px",
      "py": "8px",
      "mx": "10px"
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          backgroundColor: _vm.subCategoryParse.length == 0 ? _vm.categoryParse.length == 0 ? 'primary.50' : '#F2F2F2' : '#F2F2F2'
        }
      },
      expression: "{\n          ':hover': {\n            backgroundColor: subCategoryParse.length == 0 ? (categoryParse.length == 0 ? 'primary.50' : '#F2F2F2') : '#F2F2F2',\n          },\n        }"
    }],
    attrs: {
      "min-width": ['80px', '130px'],
      "max-width": ['80px', '150px'],
      "w": "100%",
      "border-radius": "8px",
      "background-color": _vm.subCategoryParse.length == 0 ? _vm.categoryParse.length == 0 ? 'primary.50' : '#FFF' : '#FFF',
      "text-align": "center",
      "p": "6px",
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.onChangeCategory('all');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/content_challenge/select%202.webp?updatedAt=1695810504668",
      "w": ['40px', '60px'],
      "h": ['40px', '60px'],
      "mb": "4px",
      "mx": "auto"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "12px",
      "color": "#111",
      "font-weight": "500"
    }
  }, [_vm._v(" Semua ")])], 1), _vm._l(_vm.categories, function (item) {
    return _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.categories.length > 0,
        expression: "categories.length > 0"
      }, {
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            backgroundColor: _vm.onSubcategoryIncludes(item) || _vm.categoryParse.includes(item.id) ? 'primary.50' : '#F2F2F2'
          }
        },
        expression: "{\n          ':hover': {\n            backgroundColor: onSubcategoryIncludes(item) || categoryParse.includes(item.id) ? 'primary.50' : '#F2F2F2',\n          },\n        }"
      }],
      key: item.id,
      attrs: {
        "min-width": ['90px', '130px'],
        "max-width": ['90px', '150px'],
        "w": "100%",
        "border-radius": "8px",
        "background-color": _vm.onSubcategoryIncludes(item) || _vm.categoryParse.includes(item.id) ? 'primary.50' : '#FFF',
        "text-align": "center",
        "p": "6px",
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onChangeCategory(item.id);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "src": item === null || item === void 0 ? void 0 : item.image,
        "w": ['40px', '60px'],
        "h": ['40px', '60px'],
        "mb": "4px",
        "mx": "auto"
      }
    }), _c('c-text', {
      attrs: {
        "font-size": "12px",
        "color": "#111",
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.categoryName) + " ")])], 1);
  })], 2) : _c('c-flex', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll"
    }],
    attrs: {
      "flex-direction": "row",
      "align-items": "center",
      "flex-wrap": "no-wrap",
      "gap": "4px",
      "overflow": "hidden",
      "max-width": "890px",
      "py": "8px",
      "mx": "10px"
    }
  }, _vm._l(7, function (i) {
    return _c('SkeletonMenuCategory', {
      key: i
    });
  }), 1)], 1), _vm.isLoading ? _c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": ['repeat(2, 1fr)', 'repeat(3, 1fr)'],
      "gap": ['3', '4'],
      "px": ['1rem', '0'],
      "py": "1rem",
      "background-color": ['#F2F2F2', '#FFF']
    }
  }, _vm._l(['sm', 'md'].includes(_vm.currentBreakpoint) ? 4 : 6, function (i) {
    return _c('SkeletonCardContent', {
      key: i
    });
  }), 1) : _c('c-box', [_vm.items.length > 0 ? _c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": ['repeat(2, 1fr)', 'repeat(3, 1fr)'],
      "gap": ['3', '4'],
      "px": ['1rem', '0'],
      "py": "1rem",
      "background-color": ['#F2F2F2', '#FFF']
    }
  }, _vm._l(_vm.items, function (item, i) {
    var _item$type;

    return _c('CardContent', {
      key: i,
      attrs: {
        "title": item === null || item === void 0 ? void 0 : item.title,
        "type": (_item$type = item === null || item === void 0 ? void 0 : item.type) !== null && _item$type !== void 0 ? _item$type : 'tips_gizi',
        "thumbnail": item === null || item === void 0 ? void 0 : item.coverImage
      },
      on: {
        "click": function click($event) {
          return _vm.onClickContent(item === null || item === void 0 ? void 0 : item.id);
        }
      }
    });
  }), 1) : _vm._e(), _vm.items.length > 0 ? _c('c-flex', {
    attrs: {
      "background-color": ['#F2F2F2', '#FFF'],
      "px": ['1rem', '0'],
      "pt": "8px",
      "width": "100%",
      "gap": "4"
    }
  }, [_c('BaseButton', {
    attrs: {
      "variant": "outlined",
      "flex-grow": "1",
      "height": ['40px', '60px'],
      "background-color": "#FFF",
      "left-svg-icon": require('@/assets/icons/icon-left.svg'),
      "left-svg-icon-color": "#008C81",
      "border-radius": "40px",
      "is-disabled": _vm.page == 1
    },
    on: {
      "click": _vm.onPrevious
    }
  }, [_vm._v(" Sebelumnya ")]), _c('BaseButton', {
    attrs: {
      "flex-grow": "1",
      "height": ['40px', '60px'],
      "right-svg-icon": require('@/assets/icons/icon-right.svg'),
      "right-svg-icon-color": "#FFF",
      "border-radius": "40px",
      "is-disabled": _vm.page == _vm.totalPage
    },
    on: {
      "click": _vm.onNext
    }
  }, [_vm._v(" Selanjutnya ")])], 1) : _vm._e(), _vm.items.length == 0 ? _c('c-box', {
    attrs: {
      "width": "100%",
      "p": "60px 40px",
      "bg": ['#F2F2F2', '#FFF']
    }
  }, [_c('c-image', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720",
      "width": ['200px', '300px'],
      "mx": "auto",
      "mb": "16px"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "text-align": "center"
    }
  }, [_vm._v(" Yah! materi tidak ditemukan ")])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }