<template>
  <c-box
    :min-width="['80px', '130px']"
    :max-width="['80px', '150px']"
    w="100%"
    border-radius="8px"
    background-color="#F2F2F2"
    text-align="center"
    p="6px"
  >
    <c-box
      :w="['40px', '60px']"
      :h="['40px', '60px']"
      mb="6px"
      mx="auto"
      overflow="hidden"
      border-radius="100%"
    >
      <vue-skeleton-loader
        type="react"
        width="100%"
        height="100%"
        animation="fade"
        rounded
      />
    </c-box>
    <c-box
      :height="['14px', '16px']"
      :width="['70%', '80px']"
      mx="auto"
    >
      <vue-skeleton-loader
        type="react"
        width="100%"
        height="100%"
        animation="fade"
        rounded
      />
    </c-box>
  </c-box>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'  

export default {
  name: 'SkeletonMenuCategory',
  components: {
    VueSkeletonLoader,
  },
}
</script>

<style>

</style>