import { render, staticRenderFns } from "./favorite.vue?vue&type=template&id=4d370b36&scoped=true&"
import script from "./favorite.vue?vue&type=script&lang=js&"
export * from "./favorite.vue?vue&type=script&lang=js&"
import style0 from "./favorite.vue?vue&type=style&index=0&id=4d370b36&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d370b36",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CImage: require('@chakra-ui/vue').CImage, CInput: require('@chakra-ui/vue').CInput, CFormControl: require('@chakra-ui/vue').CFormControl, CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex, CTab: require('@chakra-ui/vue').CTab, CTabList: require('@chakra-ui/vue').CTabList, CTabs: require('@chakra-ui/vue').CTabs, CGrid: require('@chakra-ui/vue').CGrid, CText: require('@chakra-ui/vue').CText})
