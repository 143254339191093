<template>
  <c-box
    pos="relative"
    w="100%"
    :bg="['#F2F2F2', 'white']"
    h="100%"
    :min-h="[$route.query.month ? 'calc(100vh - 64px)' : 'calc(100vh - 128px)', '100%']"
    pb="25px"
    height="100%"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="breadcrumbPath"
      />
    </Portal>

    <c-box
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['none', '12px']"
      w="100%"
      :px="['0', '85px']"
      :pb="['0', '30px']"
    >
      <c-box
        w="100%"
        :max-width="['inherit', '1200px']"
        mx="auto"
        :px="['0','20px']"
        bg="#FFF"
      >
        <c-flex
          v-if="['target_harian', 'materi_harian'].includes($route.query?.type) && !!$route.query?.month && isClient"
          justify-content="space-between"
          padding-top="32px"
          :display="['none', 'flex']"
          margin-bottom="8px"
        >
          <c-flex
            align-items="center"
            gap="8px"
          >
            <c-button
              variant="ghost"
              p="0"
              @click="onBackMonth"
            >
              <c-image
                w="24px"
                h="24px"
                object-fit="cover"
                :src="require('@/assets/icon-chevron-left.svg')"
              />
            </c-button>
            <c-text
              font-size="20px"
              color="#333"
              font-weight="700"
            >
              {{ capitalizeWords($route.query.type) }}
            </c-text>
          </c-flex>
          <c-box>
            <UserPoin :value="totalPoinUser" />
          </c-box>
        </c-flex>
        <c-box
          v-if="!$route.query.month || isRoleNutritionist"
          :pt="['20px', '0']"
        >
          <c-box
            justify-content="center"
            :display="['none', 'flex']"
          >
            <c-heading
              :display="isRoleNutritionist ? 'block' : ['none', 'block']"
              :font-size="isRoleNutritionist ? ['20px', '28px'] : '20px'"
              font-weight="700"
              color="black.900"
              my="30px"
            >
              Panduan Diet
            </c-heading>
          </c-box>
          <c-box
            w="100%"
            px="1rem"
          >
            <c-grid
              v-if="types.length > 0"
              width="100%"
              :template-columns="['repeat(4, 1fr)', 'repeat(4, 1fr)']"
              :gap="['2', '4']"
              mb="1rem"
            >
              <c-flex
                v-for="item in types"
                :key="item.type"
                w="100%"
                :p="['8px 6px' ,'1rem']"
                flex-direction="column"
                align-items="center"
                justify-content="center"
                :bg="item.type === type ? 'primary.400' : '#F2F2F2'"
                :color="item.type === type ? '#FFF' : '#111'"
                border-radius="1rem"
                cursor="pointer"
                box-shadow="0px 2px 2px 0px #008C8126"
                :_hover="{
                  backgroundColor: '#008C81',
                  color: '#FFFFFF'
                }"
                @click="onChangeType(item.type)"
              >
                <c-text
                  :font-size="['10px', '18px']"
                  font-weight="600"
                  mb="8px"
                  text-align="center"
                >
                  {{ item.name }}
                </c-text>
                <c-image
                  :src="item.image"
                  alt=""
                  :height="['40px', '60px']"
                />
              </c-flex>
              <c-flex
                w="100%"
                :p="['8px 6px' ,'1rem']"
                flex-direction="column"
                align-items="center"
                justify-content="center"
                :bg="type === 'favorite' ? 'primary.400' : '#F2F2F2'"
                :color="type === 'favorite' ? '#FFF' : '#111'"
                border-radius="1rem"
                cursor="pointer"
                box-shadow="0px 2px 2px 0px #008C8126"
                :_hover="{
                  backgroundColor: '#008C81',
                  color: '#FFFFFF'
                }"
                @click="onChangeType('favorite')"
              >
                <c-text
                  :font-size="['10px', '18px']"
                  font-weight="600"
                  mb="8px"
                  text-align="center"
                >
                  Favorit
                </c-text>
                <c-image
                  :src="require('@/assets/icon-love.svg')"
                  alt=""
                  :height="['38px', '60px']"
                />
              </c-flex>
            </c-grid>

            <c-grid
              v-else
              width="100%"
              :template-columns="['repeat(4, 1fr)', 'repeat(4, 1fr)']"
              :gap="['2', '4']"
              mb="1rem"
            >
              <c-flex
                v-for="item in 4"
                :key="item"
                w="100%"
                :p="['8px 6px' ,'1rem']"
                flex-direction="column"
                align-items="center"
                justify-content="center"
                bg="#F2F2F2"
                color="#111"
                border-radius="1rem"
                cursor="pointer"
                box-shadow="0px 2px 2px 0px #008C8126"
              >
                <c-box
                  :w="['45px', '100px']"
                  :h="['12px', '24px']"
                  mb="8px"
                >
                  <vue-skeleton-loader
                    type="rect"
                    width="100%"
                    height="100%"
                    animation="fade"
                    rounded
                  />
                </c-box>
                <c-box
                  :w="['40px', '60px']"
                  :h="['40px', '60px']"
                >
                  <vue-skeleton-loader
                    type="circle"
                    width="100%"
                    height="100%"
                    animation="fade"
                    rounded
                  />
                </c-box>
              </c-flex>
            </c-grid>
          </c-box>
        </c-box>

        <c-box
          v-if="type === 'materi_harian' && !isLoadingListMonthMateriHarian"
          width="100%"
        >
          <MateriHarian
            v-if="isClient"
            :type="type"
            :data="listMonthMateriHarian"
            :items="items"
            :categories="category"
            :page="page"
            :per-page="perPage"
            :total="total"
            :is-loading="isLoadingContent"
            :tab-status="tabStatus"
            :show-modal-type="showModalType"
            @on-click="onChangeMonth"
          />
          <MateriHarianNutri
            v-if="isRoleNutritionist"
            :type="type"
            :months="listMonthMateriHarian"
            :items="items"
            :categories="category"
            :page="page"
            :per-page="perPage"
            :total="total"
            :is-loading="isLoadingContent"
            @on-click="onChangeMonth"
          />
        </c-box>
        <c-box
          v-else-if="type === 'target_harian' && !isLoadingListMonthTargetHarian"
          width="100%"
        >
          <TargetHarian
            v-if="isClient"
            :type="type"
            :data="listMonthTargetHarian"
            :items="items"
            :categories="category"
            :page="page"
            :per-page="perPage"
            :total="total"
            :is-loading="isLoadingContent"
            :tab-status="tabStatus"
            :show-modal-type="showModalType"
            @on-click="onChangeMonth"
          />
          <TargetHarianNutri
            v-if="isRoleNutritionist"
            :type="type"
            :months="listMonthTargetHarian"
            :items="items"
            :categories="category"
            :page="page"
            :per-page="perPage"
            :total="total"
            :is-loading="isLoadingContent"
            @on-click="onChangeMonth"
          />
        </c-box>
        <c-box
          v-else-if="type === 'favorite'"
        >
          <Favorite
            :type="type"
            :items="items"
            :page="page"
            :per-page="perPage"
            :total="total"
            :is-empty="isContentEmpty"
            :is-loading="isLoadingContent"
          />
        </c-box>
        <c-box
          v-else-if="type === 'tips_gizi'"
        >
          <TipsGizi
            v-if="isClient"
            :type="type"
            :items="items"
            :categories="category"
            :page="page"
            :per-page="perPage"
            :total="total"
            :is-loading="isLoadingContent"
            :is-loading-category="isLoadingCategory"
          />
          <TipsGiziNutri
            v-if="isRoleNutritionist"
            :type="type"
            :items="items"
            :categories="category"
            :page="page"
            :per-page="perPage"
            :total="total"
          />
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { CBox, CFlex, CButton, CImage, CGrid, CHeading, CText } from '@chakra-ui/vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import VueSkeletonLoader from 'skeleton-loader-vue'
import TipsGizi from '@/views/content-challenge/_views/tips-gizi.vue'
import MateriHarian from '@/views/content-challenge/_views/materi-harian.vue'
import TargetHarian from '@/views/content-challenge/_views/target-harian.vue'
import TipsGiziNutri from '@/views/admin/coaching-challenge/_views/tips-gizi.vue'
import MateriHarianNutri from '@/views/admin/coaching-challenge/_views/materi-harian.vue'
import TargetHarianNutri from '@/views/admin/coaching-challenge/_views/target-harian.vue'
import Favorite from '@/views/content-challenge/_views/favorite.vue'
import { reqContentMonthGroup } from '@/requests/dietela-api/client/content-challenge/req-content-month-group'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'
import generalMixin from '@/utils/general-mixins'
import UserPoin from '@/components/elements/user-poin.vue'
import EventBus from '@/utils/event-bus'
import { reqGetTabStatus } from '@/requests/dietela-api/client/content-challenge/get-tab-status'
import { isDataEmpty } from '@/utils/is-data-empty'
import { reqContentMonthGroup as reqContentMonthGroupNutri } from '@/requests/dietela-api/nutritionist/content-challenge/req-content-month-group'
import { reqGetDetailContent } from '@/requests/dietela-api/client/content-challenge/get-detail-content'

export default {
  name: 'PanduanDiet',
  components: {
    UserPoin,
    TargetHarian,
    MateriHarian,
    BreadcrumbPath,
    VueSkeletonLoader,
    TipsGizi,
    Favorite,
    TipsGiziNutri,
    MateriHarianNutri,
    TargetHarianNutri,
    CBox,
    CFlex,
    CButton,
    CImage,
    CGrid,
    CHeading,
    CText,
  },
  mixins: [
    mixinsCheckBreakpoints(),
    generalMixin,
  ],
  data() {
    return {
      type: this.$route.query.type,
      page: 1,
      perPage: ['sm', 'md'].includes(this.currentBreakpoint) ? '10' : '15',
      listMonthMateriHarian: {},
      listMonthTargetHarian: {},
      isLoadingListMonthMateriHarian: false,
      isLoadingListMonthTargetHarian: false,
      isLoadingContent: false,
      isLoadingCategory: false,
      isContentEmpty: false,
      tabStatus: {
        semua: false,
        belumTerbaca: false,
        sudahTerbaca: false,
        dapatDibaca: false,
      },
      showModalType: null,
    }
  },
  computed: {
    ...mapState({
      user: (s) => s.auth.user,
    }),
    ...mapGetters({
      types: 'contentChallenge/types',
      items: 'contentChallenge/items',
      category: 'contentChallenge/category',
      meta: 'contentChallenge/meta',
      totalPoinUser: 'clients/totalPoinUser',
    }),
    isNutri() {
      return this.user && this.user.role === 'nutritionist'
    },
    isClient() {
      return this.user && this.user.role === 'client' 
    },
    currentPage() {
      return parseInt(this.$route.query.page ?? 1, 10)
    },
    pages() {
      let total = this.meta.total ?? 1
      let perPage = parseInt(this.perPage) ?? 10
      let length = Math.ceil(total / perPage)
      return Array.from({ length }, (_, idx) => idx + 1)
    },
    total() {
      return this.meta.total ?? 1
    },
    typeId() {
      if (this.type === 'favorite') {
        return 'favorite'
      }
      if (this.types.length > 0 && this.type) {
        return this.types.find((it) => it.type === this.type)?.id
      }
      return undefined
    },
    breadcrumbPath() {
      if (this.isClient) {
        if (['target_harian', 'materi_harian'].includes(this.type)) {
          return [
            { label: 'Dashboard', href: { name: 'client.index' } },
            { label: 'Panduan Diet', href: { name: 'client.content-challenge', query: { type: this.type } } },
            ...(this.$route.query.month ? [{ label: `Bulan ${this.$route.query.month}`, isCurrent: true }] : []),
          ]
        } else {
          return [
            { label: 'Dashboard', href: { name: 'client.index' } },
            { label: 'Panduan Diet', isCurrent: true },
          ]
        }
      } else {
        return [
          { label: 'Beranda', href: { name: 'nutrisionist.managementClient' } },
          { label: 'Panduan Diet', href: '/content-challenge' },
          { label: this.capitalizeWords(this.$route.query.type), isCurrent: true },
        ]
      }
    },
  },
  watch: {
    currentBreakpoint(v) {
      if (['sm', 'md'].includes(v) && ['target_harian', 'materi_harian'].includes(this.type) && !!this.$route.query.month) {
        if (this.isClient) {
          this.setShowTotalPoinUser(true)
        } else {
          this.setShowTotalPoinUser(false)
        }
      } else {
        this.setShowTotalPoinUser(false)
      }
    },
    type: {
      immediate: true,
      async handler(type) {
        if (this.isClient) {
          const res = await reqGetTabStatus(this.$store.getters.axios, this.$route.query?.type)
          this.tabStatus = res?.data?.data || {}
        }

        this.isLoadingListMonthMateriHarian = true
        this.isLoadingListMonthTargetHarian = true
        if (type === 'target_harian' && this.isDataEmpty(this.listMonthTargetHarian)) {
          if (this.isRoleNutritionist) {
            this.listMonthTargetHarian = await reqContentMonthGroupNutri(
              this.$store.getters.axios,
              { type: 'target_harian' },
            ).then((it) => it?.data?.data || [])
          } else if (this.isClient) {
            this.listMonthTargetHarian = await reqContentMonthGroup(
              this.$store.getters.axios,
              { type: 'target_harian' },
            ).then((it) => it?.data?.data || [])
          }
        } else if (type === 'materi_harian' && this.isDataEmpty(this.listMonthMateriHarian)) {
          if (this.isRoleNutritionist) {
            this.listMonthMateriHarian = await reqContentMonthGroupNutri(
              this.$store.getters.axios,
              { type: 'materi_harian' },
            ).then((it) => it?.data?.data || [])
          } else if (this.isClient) {
            this.listMonthMateriHarian = await reqContentMonthGroup(
              this.$store.getters.axios,
              { type: 'materi_harian' },
            ).then((it) => it?.data?.data || [])
          }
        }
        this.isLoadingListMonthMateriHarian = false
        this.isLoadingListMonthTargetHarian = false
      },
    },
    typeId: {
      async handler(val) {
        if (val && val !== 'favorite') {
          this.isLoadingCategory = true
          await this.loadContentCategories()
          this.isLoadingCategory = false
        }
      },
    },
    $route: {
      immediate: true,
      async handler(route) {
        if (['sm', 'md'].includes(this.currentBreakpoint) && ['target_harian', 'materi_harian'].includes(route.query?.type) && !!route.query?.month) {
          if (this.isClient) {
            this.setShowTotalPoinUser(true)
          } else {
            this.setShowTotalPoinUser(false)
          }
        } else {
          this.setShowTotalPoinUser(false)
        }

        if (this.isClient) {
          this.getTotalUserPoin()
        }

        // INFO: override route meta
        if (route.query.month) {
          EventBus.$emit('HEADER_WITH_BACK', true)
          EventBus.$emit('HEADER_TITLE', this.capitalizeWords(route.query.type))
          EventBus.$emit('SHOW_CART', false)
          EventBus.$emit('SHOW_NOTIFICATION', false)
          EventBus.$emit('SHOW_BOTTOM_NAVBAR', false)
        }

        if (route.query.type) {
          this.isLoadingContent = true
          this.page = route.query.page ? parseInt(route.query.page) : 1
          this.type = route.query.type ?? this.types[0]?.type
          this.perPage = route.query.perpage ? route.query.perpage : (this.isClient ? (['sm', 'md'].includes(this.currentBreakpoint) ? '10' : '15') : '5')
          let params = new URLSearchParams()
          if (route.query.q) {
            params.set('q', route.query.q)
          }
          params.set('page', this.page)
          params.set('perpage', this.perPage)

          switch (route.query?.status) {
            case 1:
            case '1':
              params.set('readStatus', '0')
              break
            case 2:
            case '2':
              params.set('readStatus', '1')
              break
            case 3:
            case '3':
              params.set('readStatus', '2')
              break
          }

          if (route.query?.tab && route.query?.tab !== 'all') {
            params.set('type', route.query?.tab)
          }

          if (route.query.type === 'favorite') {
            await this.getContentFavorite(params)
          } else {
            params.set('type', this.type)
            if (route.query.category && JSON.parse(route.query.category).length > 0) {
              params.set('category', route.query.category ?? '')
            }
            if (route.query.subCategories && JSON.parse(route.query.subCategories).length > 0) {
              params.set('subCategories', route.query.subCategories ?? '')
            }
            if (route.query.month) {
              params.set('monthGroup', route.query.month)
            }
            await this.loadContentChallenge(params)
          }
          this.isLoadingContent = false

          if (this.page === 1 && !route.query.q) {
            this.isContentEmpty = this.items.length === 0
          }
        }
      },
    },
  },
  async mounted() {
    if (this.isClient) {
      const openedListMateriOrTargetHarian = ['target_harian', 'materi_harian'].includes(this.$route.query?.type) && !!this.$route.query?.month
      const hasContentId = !!this.$route.query?.contentId
      if (openedListMateriOrTargetHarian && hasContentId) {
        const res = await reqGetDetailContent(
          this.$store.getters.axios,
          this.$route.query?.contentId,
        )
        const content = res?.data?.data
        if (content?.isReachableContent === 1 && content?.isAvailableToShare === 0) {
          this.showModalType = 'UNLOCK_CONTENT'
          return
        }
        if (content?.isReachableContent === 0) {
          if (content?.clientType === 'non-intensive') {
            this.showModalType = 'ERROR_GET_INTENSIVE_PROGRAM'
            return
          }
          if (content?.clientType === 'intensive') {
            this.showModalType = 'ERROR_GET_INTENSIVE_LONGER_DURATION'
            return
          }
        }
      }
    }

    if (this.types.length === 0) {
      await this.getContentType()
    }
    if (!this.type) {
      this.onChangeType('tips_gizi')
    }
  },
  methods: {
    isDataEmpty,
    ...mapMutations({
      setShowTotalPoinUser: 'clients/setShowTotalPoinUser',
    }),
    ...mapActions({
      getContentType: 'contentChallenge/getContentType',
      loadContentChallenge: 'contentChallenge/listContentChallengeClient',
      getContentCategory: 'contentChallenge/getContentCategory',
      getTotalUserPoin: 'clients/getTotalUserPoin',
      getContentFavorite: 'contentChallenge/getContentFavorite',
    }),
    onChangeType(value) {
      this.type = value
      this.$router.replace({
        name: this.$route.name,
        query: {
          type: value,
        },
      }).catch(() => {})
    },
    onChangeMonth(value) {
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          month: value,
          page: 1,
        },
      }).catch(() => {})
    },
    loadContentCategories() {
      let params = new URLSearchParams()
      params.set('type', this.typeId)
      params.set('page', 1)
      params.set('perpage', 10)
      params.set('subCategories', 1)
      this.getContentCategory(params)
    },
    onBackMonth() {
      this.$router.back()
    },
  },
}
</script>
