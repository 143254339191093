<template>
  <c-box
    width="100%"
  >
    <c-flex
      p="16px"
      gap="8px"
      width="100%"
      align-items="center"
      :bg="['#F2F2F2', '#FFF']"
    >
      <c-box width="100%">
        <c-form-control
          pos="relative"
          w="100%"
        >
          <c-image
            :src="require('@/assets/ic-search.svg')"
            alt="icon"
            position="absolute"
            top="50%"
            transform="translateY(-50%)"
            :left="['15px', '25px']"
            :height="['20px', '24px']"
            z-index="2"
          />
          <c-input
            id="search"
            v-model="search"
            type="text"
            w="100%"
            :height="['40px', '62px']"
            :font-size="['14px', '18px']"
            placeholder="Search"
            :border-radius="['30px', '20px']"
            :padding-left="['45px', '60px']"
            position="relative"
            z-index="1"
            @input="handleSearch"
          />
        </c-form-control>
      </c-box>
      <c-menu
        :auto-select="false"
        :close-on-select="true"
        @blur="true"
      >
        <c-menu-button
          :background-color="['white', '#F2F2F2']"
          color="gray.900"
          :p="['0', '8px 20px']"
          :border-radius="['8px', '1rem']"
          d="flex"
          align-items="center"
          gap="12px"
          :h="['40px', '62px']"
          :w="['40px', 'fit-content']"
        >
          <c-text
            :display="['none', 'block']"
            color="#333333"
            font-size="16px"
          >
            Filter
          </c-text>
          <c-box
            :display="['none', 'block']"
            :width="['20px', '30px']"
            :height="['20px', '30px']"
            gap="8px"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-filter.svg')"
              height="100%"
              width="100%"
              fill="#333333"
            />
          </c-box>
          <c-box
            :display="['block', 'none']"
            :width="['20px', '30px']"
            :height="['20px', '30px']"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-filter.svg')"
              height="100%"
              width="100%"
              fill="#008C81"
            />
          </c-box>
          <c-flex
            v-if="subCategoryParse.length > 0"
            pos="absolute"
            top="-2px"
            right="-2px" 
            :width="['8px', '10px']"
            :height="['8px', '10px']"
            align-items="center"
            justify-content="center"
            border-radius="100%"
            font-size="10px"
            background-color="danger.400"
            color="#FFF"
          >
            <!-- {{ subCategoryParse.length }} -->
          </c-flex>
        </c-menu-button>
        <c-menu-list
          z-index="10"
          :min-width="['250px', '350px']"
          :max-width="['300px', '400px']"
          flex-direction="column" 
          py="12px"
          px="8px"
          placement="left-start"
          border-radius="1rem"
        >
          <c-box
            v-for="(category, i) in categories"
            v-show="categories.length > 0"
            :key="category.id"
          >
            <c-menu-item
              v-chakra="{
                ':hover': {
                  backgroundColor: 'primary.50'
                }
              }"
              gap="12px"
              :px="['8px', '16px']"
              :py="['4px', '8px']"
              :background-color="onSubcategoryIncludes(category) || categoryParse.includes(category.id) ? 'primary.50' : '#FFF'"
              cursor="pointer"
              @click="onChangeFilter(category.id)"
            >
              <c-image
                :src="category?.image"
                width="25px"
                height="25px"
              />
              <c-text
                color="primary.400"
                :font-size="['14px', '18px']"
                :font-weight="['500']"
              >
                {{ category?.categoryName }}
              </c-text>
            </c-menu-item>
            <c-menu-group 
              v-for="sub in category?.subCategories"
              :key="sub.id"
              title=""
            >
              <c-menu-item
                v-chakra="{
                  ':hover': {
                    background: subCategoryParse.includes(sub.id) ? 'primary.50' : '#F2F2F2',
                  }
                }" 
                d="flex" 
                justify-content="space-between"
                :pl="['48px', '16px']"
                :pr="['28px', '8px']"
                :font-size="['12px', '16px']"
                font-weight="400"
                color="#000"
                :min-height="['26px', '32px']"
                :background="categoryParse.includes(category?.id) || subCategoryParse.includes(sub.id) ? 'primary.50' : '#FFF'"
                @click="onChangeSubCategory(sub?.id, category?.subCategories)"
              >
                {{ sub?.subCategoryName }}
                <inline-svg
                  :src="require('@/assets/icons/icon-chevron-right.svg')"
                  height="16px"
                  width="16px"
                />
              </c-menu-item>
            </c-menu-group> 
            <c-menu-divider
              v-if="categories.length !== (i+1)"
              border-color="#FFF"
              py="4px"
              m="0"
            />
          </c-box>
          <c-text
            v-show="categories.length == 0"
            px="1rem"
            font-size="16px"
            font-weight="500"
            color="danger.400"
          >
            Belum ada kategori
          </c-text>
        </c-menu-list>
      </c-menu>
    </c-flex>

    <c-box
      pos="relative"
      bg="#FFF"
    >
      <c-flex
        v-if="!isLoadingCategory"
        v-dragscroll
        flex-direction="row"
        align-items="center"
        flex-wrap="no-wrap"
        gap="4px"
        overflow="hidden"
        max-width="890px"
        py="8px"
        mx="10px"
      >
        <c-box
          v-chakra="{
            ':hover': {
              backgroundColor: subCategoryParse.length == 0 ? (categoryParse.length == 0 ? 'primary.50' : '#F2F2F2') : '#F2F2F2',
            },
          }"
          :min-width="['80px', '130px']"
          :max-width="['80px', '150px']"
          w="100%"
          border-radius="8px"
          :background-color="subCategoryParse.length == 0 ? (categoryParse.length == 0 ? 'primary.50' : '#FFF') : '#FFF'"
          text-align="center"
          p="6px"
          cursor="pointer"
          @click="onChangeCategory('all')"
        >
          <c-image
            src="https://ik.imagekit.io/dietela/pwa_webp/content_challenge/select%202.webp?updatedAt=1695810504668"
            :w="['40px', '60px']"
            :h="['40px', '60px']"
            mb="4px"
            mx="auto"
          />
          <c-text
            font-size="12px"
            color="#111"
            font-weight="500"
          >
            Semua
          </c-text>
        </c-box>
        <c-box
          v-for="item in categories"
          v-show="categories.length > 0"
          :key="item.id"
          v-chakra="{
            ':hover': {
              backgroundColor: onSubcategoryIncludes(item) || categoryParse.includes(item.id) ? 'primary.50' : '#F2F2F2',
            },
          }"
          :min-width="['90px', '130px']"
          :max-width="['90px', '150px']"
          w="100%"
          border-radius="8px"
          :background-color="onSubcategoryIncludes(item) || categoryParse.includes(item.id) ? 'primary.50' : '#FFF'"
          text-align="center"
          p="6px"
          cursor="pointer"
          @click="onChangeCategory(item.id)"
        >
          <c-image
            :src="item?.image"
            :w="['40px', '60px']"
            :h="['40px', '60px']"
            mb="4px"
            mx="auto"
          />
          <c-text
            font-size="12px"
            color="#111"
            font-weight="500"
          >
            {{ item?.categoryName }}
          </c-text>
        </c-box>
      </c-flex>
      <c-flex
        v-else
        v-dragscroll
        flex-direction="row"
        align-items="center"
        flex-wrap="no-wrap"
        gap="4px"
        overflow="hidden"
        max-width="890px"
        py="8px"
        mx="10px"
      >
        <SkeletonMenuCategory 
          v-for="i in 7"
          :key="i"
        />
      </c-flex>
    </c-box>

    <c-grid
      v-if="isLoading"
      width="100%"
      :template-columns="['repeat(2, 1fr)', 'repeat(3, 1fr)']"
      :gap="['3', '4']"
      :px="['1rem', '0']"
      py="1rem"
      :background-color="['#F2F2F2', '#FFF']"
    >
      <SkeletonCardContent 
        v-for="i in (['sm', 'md'].includes(currentBreakpoint) ? 4 : 6)"
        :key="i"
      />
    </c-grid>
    <c-box v-else>
      <c-grid
        v-if="items.length > 0"
        width="100%"
        :template-columns="['repeat(2, 1fr)', 'repeat(3, 1fr)']"
        :gap="['3', '4']"
        :px="['1rem', '0']"
        py="1rem"
        :background-color="['#F2F2F2', '#FFF']"
      >
        <CardContent 
          v-for="(item, i) in items"
          :key="i"
          :title="item?.title"
          :type="item?.type ?? 'tips_gizi'"
          :thumbnail="item?.coverImage"
          @click="onClickContent(item?.id)"
        />
      </c-grid>

      <c-flex
        v-if="items.length > 0"
        :background-color="['#F2F2F2', '#FFF']"
        :px="['1rem', '0']"
        pt="8px"
        width="100%"
        gap="4"
      >
        <BaseButton
          variant="outlined"
          flex-grow="1"
          :height="['40px', '60px']"
          background-color="#FFF"
          :left-svg-icon="require('@/assets/icons/icon-left.svg')"
          left-svg-icon-color="#008C81"
          border-radius="40px"
          :is-disabled="page == 1"
          @click="onPrevious"
        >
          Sebelumnya
        </BaseButton>
        <BaseButton
          flex-grow="1"
          :height="['40px', '60px']"
          :right-svg-icon="require('@/assets/icons/icon-right.svg')"
          right-svg-icon-color="#FFF"
          border-radius="40px"
          :is-disabled="page == totalPage"
          @click="onNext"
        >
          Selanjutnya
        </BaseButton>
      </c-flex>

      <c-box
        v-if="items.length == 0"
        width="100%"
        p="60px 40px"
        :bg="['#F2F2F2', '#FFF']"
      >
        <c-image
          src="https://ik.imagekit.io/dietela/pwa_webp/content_challenge/undraw_empty_re_opql%202.webp?updatedAt=1695810504720"
          :width="['200px', '300px']"
          mx="auto"
          mb="16px"
        />
        <c-text
          :font-size="['16px', '18px']"
          font-weight="500"
          text-align="center"
        >
          Yah! materi tidak ditemukan
        </c-text>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { dragscroll } from 'vue-dragscroll'
import BaseButton from '@/components/elements/base-button.vue'
import CardContent from '@/views/content-challenge/_widgets/card-content.vue'
import _ from 'lodash'
import SkeletonCardContent from '@/views/content-challenge/_widgets/skeleton-card-content.vue'
import SkeletonMenuCategory from '@/views/content-challenge/_widgets/skeleton-menu-category.vue'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'

export default {
  name: 'TipsGizi',
  components: {
    BaseButton,
    CardContent,
    SkeletonCardContent,
    SkeletonMenuCategory,
  },
  directives: {
    dragscroll,
  },
  mixins: [
    mixinsCheckBreakpoints(),
  ],
  props: {
    type: {
      type: String,
      default: 'tips-gizi',
    },
    items: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: String,
      default: '10',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoadingCategory: {
      type: Boolean,
      default: false,
    },
  },
  emits: [],
  data() {
    return {
      search: this.$route.query.q ?? '',
      debounceSearch: _.debounce(this.onSearch, 1000),
    }
  },
  computed: {
    categoryParse() {
      return this.$route.query?.category ? JSON.parse(this.$route.query?.category) : []
    },
    subCategoryParse() {
      return this.$route.query?.subCategories ? JSON.parse(this.$route.query?.subCategories) : []
    },
    totalPage() {
      return Math.ceil(this.total / Number(this.perPage))
    },
  },
  methods: {
    onClickContent(id) {
      this.$router.push({
        name: 'content-challenge.detail',
        params: {
          id: id,
        },
      })
    },
    handleSearch() {
      this.debounceSearch()
    },
    onSearch() {
      this.$router.replace({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          q: this.search,
          page: 1,
        },
        params: { savePosition: true },
      })
    },
    onPrevious() {
      if (this.page > 1) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page: (this.page - 1) },
        })
      }
    },
    onNext() {
      if (this.page < this.totalPage) {
        this.$router.push({
          name: this.$route.name,
          query: { ...this.$route.query, page: (this.page + 1) },
        })
      }
    },
    onChangeCategory(value) {
      if (value == 'all') {
        this.$router.push({
          name: this.$route.name,
          query: { 
            ...this.$route.query,
            page: 1,
            category: '',
            subCategories: '',
          },
          params: { savePosition: true },
        }).catch(() => {})
      } else {
        let category = [value]
        category = JSON.stringify(category)
        this.$router.push({
          name: this.$route.name,
          query: { 
            ...this.$route.query,
            page: 1,
            category: category.length == 0 ? '' : category,
            subCategories: '',
          },
          params: { savePosition: true },
        }).catch(() => {})
      }
    },
    onChangeFilter(value) {
      let category = this.categoryParse
      if (category && category?.some((it) => it === value)) {
        this.onChangeCategory('all')
      } else {
        category = JSON.stringify([value])
        this.$router.push({
          name: this.$route.name,
          query: { 
            ...this.$route.query,
            page: 1,
            category: category.length == 0 ? '' : category,
            subCategories: '',
          },
          params: { savePosition: true },
        }).catch(() => {})
      }
    },
    onChangeSubCategory(value, list) {
      let subcategory = this.subCategoryParse
      if (list.some((it) => it.id === value)) {
        if (subcategory && subcategory?.some((it) => it == value)) {
          subcategory = subcategory?.filter((it) => it != value)
        } else {
          subcategory.push(value)
        }
      } else {
        subcategory = [value]
      }
      subcategory = JSON.stringify(subcategory)
      this.$router.push({
        name: this.$route.name,
        query: { 
          ...this.$route.query,
          page: 1,
          category: '',
          subCategories: subcategory.length == 0 ? '' : subcategory,
        },
        params: { savePosition: true },
      }).catch(() => {})
    },
    onSubcategoryIncludes(category) {
      if (this.subCategoryParse.length > 0 && category?.subCategories?.length > 0) {
        return category?.subCategories.some((it) => it.id == this.subCategoryParse[0])
      }
      return false
    },
  },
}
</script>

<style>

</style>