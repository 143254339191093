var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "gap": "6px",
      "border-radius": "8px",
      "box-shadow": "2px 2px 10px 0px #00000026",
      "background-color": "#FFF",
      "cursor": "pointer",
      "transition": "all 0.2s ease-in-out",
      "position": "relative",
      "_hover": {
        backgroundColor: '#e8e7e7'
      }
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('c-box', {
    attrs: {
      "pos": "relative"
    }
  }, [_c('c-image', {
    attrs: {
      "border-top-radius": "8px",
      "src": _vm.thumbnail,
      "object-fit": "cover",
      "height": "100px",
      "width": "100%",
      "flex-shrink": "0"
    }
  }), !_vm.isLocked && _vm.type != 'tips_gizi' ? _c('c-box', {
    attrs: {
      "pos": "absolute",
      "top": "6px",
      "right": "6px"
    }
  }, [_vm.isReaded ? _c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-read.svg'),
      "height": "25",
      "fill": "#008C81"
    }
  }) : _c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-unlock.svg'),
      "height": "25",
      "fill": "#008C81"
    }
  })], 1) : _vm._e(), _vm.isLocked && _vm.type != 'tips_gizi' ? _c('c-flex', {
    attrs: {
      "pos": "absolute",
      "top": "0",
      "width": "100%",
      "height": "100%",
      "justify": "center",
      "align": "center",
      "background-color": "rgba(0, 0, 0, 0.4)",
      "border-top-radius": "8px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-lock.svg'),
      "height": "40",
      "fill": "#008C81"
    }
  })], 1) : _vm._e()], 1), _c('c-flex', {
    attrs: {
      "height": "100%",
      "flex-direction": "column",
      "justify-content": "space-between",
      "p": "5px 10px",
      "border-bottom-radius": "8px"
    }
  }, [_c('c-text', {
    staticClass: "line-clamp-3",
    attrs: {
      "mb": "8px",
      "font-size": ['14px', '18px'],
      "color": "primary.400",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('c-flex', {
    attrs: {
      "font-size": ['12px', '14px'],
      "color": "#555",
      "font-weight": "400",
      "align-items": "center",
      "gap": "6px"
    }
  }, [_vm._v(" Selengkapnya > ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }