<template>
  <c-flex
    width="100%"
    flex-direction="column"
    gap="6px"
    border-radius="8px"
    box-shadow="2px 2px 10px 0px #00000026"
    background-color="#FFF"
    cursor="pointer"
    transition="all 0.2s ease-in-out"
    position="relative"
    :_hover="{
      backgroundColor: '#e8e7e7',
    }"
    @click="$emit('click')"
  >
    <c-box
      pos="relative"
    >
      <c-image 
        border-top-radius="8px"
        :src="thumbnail"
        object-fit="cover"
        height="100px"
        width="100%"
        flex-shrink="0"
      />
      <c-box
        v-if="!isLocked && type != 'tips_gizi'"
        pos="absolute"
        top="6px"
        right="6px"
      >
        <inline-svg
          v-if="isReaded"
          :src="require('@/assets/icons/icon-read.svg')"
          height="25"
          fill="#008C81"
        />
        <inline-svg
          v-else
          :src="require('@/assets/icons/icon-unlock.svg')"
          height="25"
          fill="#008C81"
        />
      </c-box>
      <c-flex
        v-if="isLocked && type != 'tips_gizi'"
        pos="absolute"
        top="0"
        width="100%"
        height="100%"
        justify="center"
        align="center"
        background-color="rgba(0, 0, 0, 0.4)"
        border-top-radius="8px"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-lock.svg')"
          height="40"
          fill="#008C81"
        />
      </c-flex>
    </c-box>
    <c-flex
      height="100%"
      flex-direction="column"
      justify-content="space-between"
      p="5px 10px"
      border-bottom-radius="8px"
    >
      <c-text 
        mb="8px"
        :font-size="['14px', '18px']"
        color="primary.400"
        font-weight="500"
        class="line-clamp-3"
      >
        {{ title }}
      </c-text>
      <c-flex
        :font-size="['12px', '14px']"
        color="#555"
        font-weight="400"
        align-items="center"
        gap="6px"
      >
        Selengkapnya >
      </c-flex>
    </c-flex>
  </c-flex>
</template>

<script>
export default {
  name: 'CardContent',
  props: {
    type: {
      type: String,
      default: 'tips_gizi',
    },
    title: {
      type: String,
      default: '',
    },
    thumbnail: {
      type: String,
      default: '',
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    isReaded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
  },
}
</script>

<style>

</style>