var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "min-width": ['80px', '130px'],
      "max-width": ['80px', '150px'],
      "w": "100%",
      "border-radius": "8px",
      "background-color": "#F2F2F2",
      "text-align": "center",
      "p": "6px"
    }
  }, [_c('c-box', {
    attrs: {
      "w": ['40px', '60px'],
      "h": ['40px', '60px'],
      "mb": "6px",
      "mx": "auto",
      "overflow": "hidden",
      "border-radius": "100%"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "react",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1), _c('c-box', {
    attrs: {
      "height": ['14px', '16px'],
      "width": ['70%', '80px'],
      "mx": "auto"
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "react",
      "width": "100%",
      "height": "100%",
      "animation": "fade",
      "rounded": ""
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }