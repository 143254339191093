import { render, staticRenderFns } from "./materi-harian.vue?vue&type=template&id=57bb8e81&scoped=true&"
import script from "./materi-harian.vue?vue&type=script&lang=js&"
export * from "./materi-harian.vue?vue&type=script&lang=js&"
import style0 from "./materi-harian.vue?vue&type=style&index=0&id=57bb8e81&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57bb8e81",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CGrid: require('@chakra-ui/vue').CGrid, CImage: require('@chakra-ui/vue').CImage, CInput: require('@chakra-ui/vue').CInput, CFormControl: require('@chakra-ui/vue').CFormControl, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CMenuButton: require('@chakra-ui/vue').CMenuButton, CMenuItem: require('@chakra-ui/vue').CMenuItem, CMenuGroup: require('@chakra-ui/vue').CMenuGroup, CMenuDivider: require('@chakra-ui/vue').CMenuDivider, CMenuList: require('@chakra-ui/vue').CMenuList, CMenu: require('@chakra-ui/vue').CMenu, CTab: require('@chakra-ui/vue').CTab, CTabList: require('@chakra-ui/vue').CTabList, CTabs: require('@chakra-ui/vue').CTabs})
